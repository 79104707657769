import axios from 'axios'
const axiosClientWithoutAuth = axios.create()

// Intercept request
axiosClientWithoutAuth.interceptors.request.use(
    (request) => {
        request.headers['Content-Type'] = 'application/json'
        return request
    },
    null,
    { synchronous: true }
)

// Intercept response
axiosClientWithoutAuth.interceptors.response.use(
    (response) => {
        if (response?.status === 201 || response?.status === 200) {
            return response.data
        }
        return response?.data
    }
    ,
    (error) => {

        return Promise.reject(error.response.data)

    }
)

// 

// axiosClientWithoutAuth.defaults.baseURL ="";

axiosClientWithoutAuth.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
}

// All request will wait 1 min before timeout
axiosClientWithoutAuth.defaults.timeout = 60000

// axiosClientWithoutAuth.defaults.withCredentials = true;

export default axiosClientWithoutAuth